<adxad-loader
  adxadSidebarModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<adxad-sidebar-modal-container
  class="creative-view"
  *ngIf="!isLoading"
>
  <adxad-sidebar-modal-header>
    <adxad-sidebar-modal-close-btn (click)="close()"></adxad-sidebar-modal-close-btn>

    <h1 adxadSidebarModalTitle>{{ 'payment' | transloco }}</h1>
  </adxad-sidebar-modal-header>

  <adxad-sidebar-modal-body>
    <section
      adxadSidebarModalSection
      class="row"
    >
      <adxad-sidebar-modal-info-block
        class="col-6"
        label="{{ 'label_date' | transloco }}"
      >
        {{ paymentDetails.date | date: 'M/d/yy, h:mm a' }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="{{ 'label_owner' | transloco }}"
      >
        {{ paymentDetails.email }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="{{ 'label_paymentMethod' | transloco }}"
      >
        {{ paymentType }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="{{ 'label_status' | transloco }}"
      >
        <span
          class="payment-status"
          [ngClass]="paymentStatus"
        >
          {{ paymentStatus | transloco }}
        </span>
      </adxad-sidebar-modal-info-block>
    </section>

    <section
      adxadSidebarModalSection
      class="row"
    >
      <adxad-sidebar-modal-info-block
        class="col-6"
        label="{{ 'label_fee' | transloco }}"
      >
        {{ paymentDetails.fee | currency }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        *ngIf="paymentDetails.vat"
        class="col-6"
        label="VAT"
      >
        {{ paymentDetails.vat | currency}}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="{{ 'label_forTopUp' | transloco }}"
      >
        {{ paymentDetails.amount | currency }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="{{ 'label_total' | transloco }}"
      >
        {{ paymentDetails.total | currency}}
      </adxad-sidebar-modal-info-block>
    </section>

    <section
      adxadSidebarModalSection
      class="row"
      [hideSeparator]="true"
    >
      <h4 class="col-12">{{ 'paymentInformation' | transloco }}</h4>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="{{ 'label_fullName' | transloco }}"
      >
        {{ paymentDetails.name }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="{{ 'label_email' | transloco }}"
      >
        {{ paymentDetails.email }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="{{ 'label_country' | transloco }}"
      >
        {{ paymentDetails.country_name }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="{{ 'label_city' | transloco }}"
      >
        {{ paymentDetails.city }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="{{ 'label_zipCode' | transloco }}"
      >
        {{ paymentDetails.zip }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        class="col-6"
        label="{{ 'label_address' | transloco }}"
      >
        {{ paymentDetails.address }}
      </adxad-sidebar-modal-info-block>

      <adxad-sidebar-modal-info-block
        *ngIf="paymentDetails.vat_number"
        class="col-6"
        label="VAT"
      >
        {{ paymentDetails.vat_number }}
      </adxad-sidebar-modal-info-block>
    </section>
  </adxad-sidebar-modal-body>

  <adxad-sidebar-modal-actions *ngIf="paymentDetails.invoice.length">
    <button
      adxadSidebarModalAction
      actionType="main-blue"
      appDownloadFile
      [downloadUrl]="paymentDetails.invoice"
      (downloadLoading)="loadingInvoice = $event.loading"
      [disabled]="loadingInvoice"
    >
      <adxad-loader
        class="submit-loader"
        [diameter]="30"
        *ngIf="loadingInvoice"
      ></adxad-loader>

      <ng-container *ngIf="!loadingInvoice">
        <span class="material-icons">cloud_download</span>
        {{ 'action_downloadInvoice' | transloco }}
      </ng-container>
    </button>
  </adxad-sidebar-modal-actions>
</adxad-sidebar-modal-container>
