import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { AdxadSidebarModalRef } from '../../../../ui/modules/sidebar-modal/sidebar-modal-ref';
import { AdxadSidebarModalConfig } from '../../../../ui/modules/sidebar-modal/sidebar-modal-config';
import { PaymentsService } from '../../payments.service';
import { AdxadAlerts } from '../../../../ui/modules/alerts/components/alerts/alerts.component';
import { QueryParamsService } from '../../../../core/services/query-params.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-payment-view',
  templateUrl: './payment-view.component.html',
  styleUrls: ['./payment-view.component.scss']
})
export class PaymentViewComponent implements OnInit, OnDestroy {
  isLoading = false;
  paymentDetails;
  loadingInvoice = false;
  private destroyRef = inject(DestroyRef);

  constructor(
    private modalRef: AdxadSidebarModalRef,
    public config: AdxadSidebarModalConfig,
    private paymentsService: PaymentsService,
    private alerts: AdxadAlerts,
    private queryParamsService: QueryParamsService
  ) {}

  ngOnInit(): void {
    if (!this.config || !this.config.data.id) {
      this.close();
    }

    this.queryParamsService.add('sm_paymentView', this.config.data.id);
    this.loadData(this.config.data.id);
  }

  /**
   * Get payment information by payment id
   *
   * @param id
   */
  loadData(id: string): void {
    this.isLoading = true;
    this.paymentsService
      .getPaymentDetails({ id: id })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        result => {
          this.isLoading = false;
          this.paymentDetails = result.data;
        },
        () => this.close()
      );
  }

  /**
   * @return {string} name of payment type
   */
  get paymentType(): string {
    if (this.paymentDetails.type === 1) {
      return 'Credit card';
    }

    if (this.paymentDetails.type === 2) {
      return 'Wire';
    }

    if (this.paymentDetails.type === 3) {
      return 'Capitalist';
    }

    if (this.paymentDetails.type === 4) {
      return 'VISA / MasterCard';
    }

    if (this.paymentDetails.type === 5) {
      return 'Paxum';
    }
  }

  /**
   * @return {string} name of payment status
   */
  get paymentStatus(): string {
    switch (this.paymentDetails.status) {
      case 0: {
        return 'paymentStatus_draft';
      }
      case 1: {
        return 'paymentStatus_pending';
      }
      case 2: {
        return 'paymentStatus_paid';
      }
      case 3: {
        return 'paymentStatus_rejected';
      }
    }
  }

  /**
   * Close modal
   */
  close(): void {
    this.modalRef.close();
  }

  ngOnDestroy(): void {
    this.queryParamsService.remove('sm_paymentView');
  }
}
