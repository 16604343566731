import { Routes } from '@angular/router';
import { PaymentsGridComponent } from './components/payments-grid/payments-grid.component';

export const routes: Routes = [
  {
    path: '',
    component: PaymentsGridComponent,
    title: 'Payments',
    data: {
      pageType: 'PAYMENTS_GRID'
    }
  }
];
