import { NgModule } from '@angular/core';
import { GuiModule } from '../../gui/gui.module';

import { PaymentsGridComponent } from './components/payments-grid/payments-grid.component';
import { PaymentViewComponent } from './components/payment-view/payment-view.component';
import { AddFundsFormComponent } from './components/add-funds-form/add-funds-form.component';
import { DynamicGridModule } from '../../dynamic-modules/dynamic-grid/dynamic-grid.module';
import { DynamicFilterModule } from '../../dynamic-modules/dynamic-filter/dynamic-filter.module';
import { TranslocoModule } from '@jsverse/transloco';
import { RouterModule } from '@angular/router';
import { routes } from './payments.routes';

const ENTRY_MODULES = [GuiModule, DynamicGridModule, DynamicFilterModule];

const ENTRY_COMPONENTS = [AddFundsFormComponent, PaymentsGridComponent, PaymentViewComponent];

@NgModule({
  imports: [...ENTRY_MODULES, TranslocoModule, RouterModule.forChild(routes)],
  declarations: [...ENTRY_COMPONENTS]
})
export class PaymentsModule {}
