<div
  [class.--menu-opened]="this.layoutService.isMenuStaticOpened"
  class="adxad-main-container"
>
  <section class="adxad-toolbar">
    <div class="filter-header row">
      <div class="col-auto">
        <adxad-calendar
          [disabled]="isLoading"
          (changeDates)="filter.changeCalendarDates($event)"
          [from]="filter.calendar?.value?.calendar.from"
          [to]="filter.calendar?.value?.calendar.to"
        ></adxad-calendar>
      </div>

      <div class="col-auto filters-btns">
        <adxad-filter-actions
          [count]="filter.count"
          [notApplied]="filter.isNotApplied"
          [disabled]="isLoading"
          (openFilter)="openFilter()"
          (clearFilters)="clearFilters()"
          (applyFilter)="applyFilter()"
        />
      </div>
    </div>
  </section>

  <adxad-dynamic-grid
    (action)="gridAction($event)"
    (reload)="loadGrid()"
    [loading]="isLoading"
    [ngClass]="{ 'hide': isNotFound || isNotCreated }"
  />

  <adxad-grid-not-found
    (clearFilters)="clearFilters()"
    *ngIf="isNotFound"
  />

  <section
    class="not-created"
    *ngIf="isNotCreated"
  >
    <img
      class="not-creates__icon"
      src="/assets/images/icons/not-created-icon.svg"
      alt="No payments"
    />
    <h2 class="not-created__title">No payments</h2>
    <p class="not-created__text">There`s no payments yet.</p>
    <button
      adxadButton
      color="red"
      (click)="openAddFundsModal()"
      *ngIf="false"
    >
      {{ 'Add funds' | uppercase }}
    </button>
  </section>

  <adxad-paginator
    (changePage)="loadGrid()"
    [disabled]="isLoading"
  />
</div>
