import { Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { DynamicGridComponent } from '../../../../dynamic-modules/dynamic-grid/components/dynamic-grid/dynamic-grid.component';
import { PaginatorComponent } from '../../../../ui/modules/paginator/paginator.component';
import { FiltersService } from '../../../../core/services/filters.service';
import { AdxadAlerts } from '../../../../ui/modules/alerts/components/alerts/alerts.component';
import { AdxadSidebarModal } from '../../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { PaymentViewComponent } from '../payment-view/payment-view.component';
import { DynamicGridAction, FilterChanges, FiltersConfig, PaymentsData } from '../../../../core/interface';
import { DspService } from '../../../../core/services/dsp.service';
import { AddFundsFormComponent } from '../add-funds-form/add-funds-form.component';
import { AdxadModal } from '../../../../ui/modules/modal/modal.service';
import { PaymentsService } from '../../payments.service';
import { SidebarFilterComponent } from '../../../../dynamic-modules/dynamic-filter/components/sidebar-filter/sidebar-filter.component';
import { TranslocoService } from '@jsverse/transloco';
import { LayoutService } from '../../../../layout/layout.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-payments-grid',
  templateUrl: './payments-grid.component.html',
  styleUrls: ['./payments-grid.component.scss']
})
export class PaymentsGridComponent implements OnInit {
  @ViewChild(DynamicGridComponent, { static: true }) dynamicGrid: DynamicGridComponent;
  @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;
  isLoading = false;
  isNotFound = false;
  isNotCreated = false;
  private destroyRef = inject(DestroyRef);

  constructor(
    public filter: FiltersService,
    private alerts: AdxadAlerts,
    private sidebarModal: AdxadSidebarModal,
    private dspService: DspService,
    private modal: AdxadModal,
    private paymentsService: PaymentsService,
    private translate: TranslocoService,
    public layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.paginator.init();
    this.loadFilters();
  }

  /**
   * Load payments grid
   */
  loadGrid(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const request = {
      sort: this.dynamicGrid && this.dynamicGrid.sort && this.dynamicGrid.sort.active,
      direction: this.dynamicGrid && this.dynamicGrid.sort && this.dynamicGrid.sort.direction.toString(),
      limit: this.paginator.limit,
      page: this.paginator.page,
      filter: this.filter.request
    };

    this.resetGrid();
    this.paymentsService
      .getPayments(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (result: PaymentsData) => {
          this.isLoading = false;

          if (!result || !result.meta || !result.meta.columns || result.status !== 'OK') {
            this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
            return;
          }

          if (result.data && result.data.length) {
            this.dynamicGrid.setGrid(result);
            this.paginator.createList(result.meta.total);
          } else {
            result.meta.isFilterable ? (this.isNotFound = true) : (this.isNotCreated = true);
          }
        },
        () => (this.isLoading = false)
      );
  }

  /**
   * Load filters list
   */
  loadFilters(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.dspService
      .getFilters('payments')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ({ data, status }: FiltersConfig) => {
          this.isLoading = false;

          if (status === 'OK') {
            this.subscribeFilter();
            this.filter.init({ data });
            this.loadGrid();
          }
        },
        () => (this.isLoading = false)
      );
  }

  /**
   * Subscribe on filter changes
   */
  subscribeFilter(): void {
    this.filter.changeFilter.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: FilterChanges) => {
      if (data && data.submit) {
        this.paginator.resetPage();
        this.loadGrid();
      }
    });
  }

  /**
   * Clear dynamic grid,
   * Clear paginator,
   * not created & not found flags set false
   */
  resetGrid(): void {
    this.dynamicGrid.clearGrid();
    this.paginator.clearList();
    this.isNotFound = false;
  }

  /**
   * Open sidebar modal for view payment details
   *
   * @param id
   */
  openSidebarModal(id: string): void {
    if (!id) {
      this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
      return;
    }

    this.sidebarModal.open(PaymentViewComponent, {
      data: {
        id: id
      }
    });
  }

  /**
   * openView - load & open payment detail in sidebar modal
   *
   * @param {DynamicGridAction} action contains type and value
   */
  gridAction(action: DynamicGridAction): void {
    if (!action || !action.value || !action.action) {
      return;
    }

    if (action.action === 'openView') {
      if (!action.value.payment_id) {
        return;
      }
      this.openSidebarModal(action.value.payment_id);
    }
  }

  /**
   * Open add funds modal
   */
  openAddFundsModal(): void {
    this.modal.open(AddFundsFormComponent, {
      width: '588px'
    });
  }

  /**
   * Open sidebar filter
   */
  openFilter(): void {
    if (this.isLoading) {
      return;
    }

    this.sidebarModal.open(SidebarFilterComponent, { data: this.filter });
  }

  /**
   * Apply filter by btn
   * Reset current page
   */
  applyFilter(): void {
    this.paginator.resetPage();
    this.loadGrid();
  }

  /**
   * Reset current page
   * Clear filter values
   * Reload grid
   */
  clearFilters(): void {
    this.paginator.resetPage();
    this.filter.clearValues();
    this.loadGrid();
  }
}
